"use client";

import { usePostHog } from "posthog-js/react";
import { ReactNode, createContext, useContext, useEffect } from "react";

import type { User } from "@/types/user";
import { useUser } from "@auth0/nextjs-auth0/client";
import { UserType } from "@prisma/client";
import * as Sentry from "@sentry/nextjs";

import { getInitialsFromName } from "@helpers/profile";
import { useProfile } from "@hooks/useProfile";

const isProduction = process.env.NODE_ENV === "production";

export type UserContextType = User & {
  userId?: string;
  isAuthenticated: boolean;
  isUnauthenticated: boolean;
  isLoading: boolean;
  isAdmin: boolean;
  initials: string;
  givenName?: string;
};

export const UserContext = createContext<UserContextType>({} as UserContextType);

const UserProvider = ({ children }: { children: ReactNode }) => {
  const posthog = usePostHog();
  const { user: data, isLoading } = useUser();
  const { data: user } = useProfile(data as User);

  const userId = user?.id;
  const isAuthenticated = isLoading === false && !!data;
  const isUnauthenticated = isLoading === false && !data;
  const initials = getInitialsFromName(user?.name as string);
  const givenName = user?.name?.split(" ")[0];
  const isAdmin = user?.roles?.includes(UserType.ADMIN);

  useEffect(() => {
    if (isProduction && data?.email) {
      const { email, name, sub } = data;
      const id = sub as string;

      posthog.identify(id, { email, name });
      Sentry.setUser({ id, email, username: name as string });
    }
  }, [data?.email, isProduction]);

  return (
    <UserContext.Provider
      value={{
        ...user,
        userId,
        isLoading,
        isAuthenticated,
        isUnauthenticated,
        initials,
        givenName,
        isAdmin,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;

export const useAuth = () => useContext<UserContextType>(UserContext);

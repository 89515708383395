"use client";

import Link from "next/link";
import { SyntheticEvent, useRef, useState } from "react";

import { UserContextType, useAuth } from "@context/User";
import { UserStatus, UserType } from "@prisma/client";

import { ClickAwayListener, Grow, Paper, Popper } from "@mui/material";

import { Avatar, AvatarFallback, AvatarImage } from "@components/ui/avatar";

import { userMenu } from "@configs/features/menus";

const UserMenu = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const user = useAuth();

  const { name, email, initials, image } = user;

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: Event | SyntheticEvent) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) return;

    setOpen(false);
  };

  return (
    <div>
      <button className="border rounded-full p-1" onClick={handleToggle} ref={anchorRef}>
        <Avatar className="w-12 h-12">
          <AvatarImage src={image as string} />
          <AvatarFallback className="text-[16px]">{initials}</AvatarFallback>
        </Avatar>
      </button>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        transition
        disablePortal
        sx={{ minWidth: 200, zIndex: 1201 }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "left top" }}>
            <Paper>
              {!!name && (
                <div className="p-4 border-b">
                  <p className="uppercase text-[#333] font-semibold text-[14px] mb-1">{name}</p>
                  <p className="text-[14px]">{email}</p>
                </div>
              )}
              <ClickAwayListener onClickAway={handleClose}>
                <div className="flex flex-col py-2">
                  {userMenu.map(({ href, label, onClick }, index) => {
                    if (!shouldHideMenuItem(href, user)) {
                      const Component = label === "Logout" ? "a" : Link;

                      return (
                        <Component
                          key={index}
                          href={href}
                          onClick={onClick}
                          className="bg-white hover:bg-gray-100 px-4 py-2"
                        >
                          {label}
                        </Component>
                      );
                    }
                  })}
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default UserMenu;

// ===============================================

function shouldHideMenuItem(href: string, { status, organization, roles }: UserContextType) {
  const isUserOnboarding = status === UserStatus.ONBOARDING && href !== "/";
  const isLeaderDashUnavailable = href === "/leader/dashboard" && !organization?.leaderDashboard;
  const isNotAdmin = href === "/admin" && !roles?.includes(UserType.ADMIN);

  return isLeaderDashUnavailable || isUserOnboarding || isNotAdmin;
}
